<template>
  <div class="no-wrapper">
    <a-empty :image="simpleImage">
      <template #description>
        至少添加一位签署方
      </template>
    </a-empty>
  </div>
</template>
<script setup>
import { Empty } from 'ant-design-vue'
const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
</script>
<style lang="less" scoped>
.no-wrapper {
  margin-top: 16px;
  width: 100%;
  height: 116px;
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #969799;
}
</style>
