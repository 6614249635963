<!-- @xjm v2.5.10  2022.11.2 -->
<template>
  <div id="elec-wrapper">
    <a-form ref="formRef" :model="formState" layout="vertical" :rules="rules">
      <a-card title="基本信息">
        <a-row>
          <a-col :span="8">
            <a-form-item label="流程主题" name="fileName">
              <a-col :span="18">
                <a-input placeholder="请输入流程主题" :maxlength="30" v-model:value="formState.fileName" />
              </a-col>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="申请印章" name="sealId">
              <a-col :span="18">
                <a-select v-model:value="formState.sealId" @change="sealChange" placeholder="请选择电子印章" optionFilterProp="staff"
                :showSearch="true">
                  <a-select-option v-for="item in elecSelList" :key="item.sealId" :value="item.sealId" :staff="item.sealName">{{
                    item.sealName
                  }}</a-select-option>
                </a-select>
              </a-col>
            </a-form-item>
          </a-col>
        </a-row>
        <div v-if="formState.sealId">
          <a-row>
            <a-col :span="8">
              <a-form-item label="审批流程" name="flowId">
                <a-select
                  v-model:value="formState.flowId"
                  :disabled="!!applyProcessList[0].disabled"
                  placeholder="请选择审批流程"
                  @change="handleProcessChange"
                  style="width: 75%"
                >
                  <a-select-option v-for="item in applyProcessList" :key="item.flowId" :value="item.flowId">{{
                    item.flowName
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <!--        审批流程头像展示-->
          <userAvatarTemplate :useList="approvalStaffList" />
        </div>
        <a-form-item :label="'签约文件（' + (formState.applyFileIds?.length ?? 0) + '/50)'" name="applyFileIds">
          <div style="width: 300px">
            <lineupload
              :fileList="formState.applyFileIds"
              :count="50"
              :action="action"
              :size="30"
              @fileChange="fileChange"
              :uploadText="uploadText"
              :fileType="['pdf', 'doc', 'docx', 'xlsx', 'xls', 'jpeg', 'jpg', 'png']"
            />
          </div>
        </a-form-item>
        <div class="sign-content">
          <div class="sign-head flex-layout">
            <div class="flex-layout">
              <h6 class="head-title">签署设置</h6>
              <a-form-item name="ordinal" style="padding-bottom:0">
                <a-radio-group v-model:value="formState.ordinal">
                  <a-radio :value="true">顺序签署</a-radio>
                  <a-radio :value="false">无序签署</a-radio>
                </a-radio-group>
              </a-form-item>
            </div>
            <div class="singn-opr">
              <a-dropdown>
                <template #overlay>
                  <a-menu>
                    <a-menu-item key="1" @click="addSigner(1)" v-if="canAdd">添加发起方</a-menu-item>
                    <a-menu-item key="2" @click="addSigner(2)">添加企业</a-menu-item>
                    <a-menu-item key="3" @click="addSigner(3)">添加个人</a-menu-item>
                  </a-menu>
                </template>
                <a-button>
                  添加签署方
                  <DownOutlined />
                </a-button>
              </a-dropdown>
            </div>
          </div>
          <SignCard
            v-for="(item, index) in formState.signatories"
            :key="item.serialNo"
            :signLen="formState.signatories.length"
            :signItem="item"
            :canUp="index !== 0"
            :canDown="index !== formState.signatories.length - 1"
            v-model:signatories="formState.signatories"
          >
            <a-row v-if="item.type === 0" class="flex-between fqf-item ">
              <a-col>
                企业名称：
                <span class="enterprise-name">{{ item.name }}</span>
              </a-col>
              <a-col>
                <div class="flex-layout">
                  <a-form-item
                    :name="['signatories', index, 'operator']"
                    :rules="{
                      type: 'any'
                    }"
                  >
                    <a-checkbox v-model:checked="item.operator">经办人签字</a-checkbox>
                  </a-form-item>
                  <a-form-item
                    :name="['signatories', index, 'legalPerson']"
                    :rules="{
                      type: 'any'
                    }"
                  >
                    <a-checkbox v-model:checked="item.legalPerson">法定代表人签字</a-checkbox>
                  </a-form-item>
                  <a-form-item
                    :name="['signatories', index, 'company']"
                    :rules="{
                      type: 'any'
                    }"
                  >
                    <a-checkbox v-model:checked="item.company">企业签章</a-checkbox>
                  </a-form-item>
                </div>
                <div class="warn-label" v-if="!item.operator && !item.legalPerson && !item.company">请选择签署人</div>
              </a-col>
            </a-row>
            <a-row :gutter="16" v-if="item.type === 1 && item.signatoryType === 0" class="flex-between">
              <div class="flex-layout padding24-style">
                <a-col>
                  <a-form-item
                    label="企业名称"
                    :name="['signatories', index, 'name']"
                    :rules="{
                      required: true,
                      message: '请输入签署方企业名称'
                    }"
                  >
                    <a-input
                      placeholder="请输入签署方企业名称"
                      v-model:value="item.name"
                      :maxLength="20"
                      style="width: 240px;"
                    />
                  </a-form-item>
                </a-col>
                <a-col>
                  <a-form-item
                    label="经办人姓名"
                    :name="['signatories', index, 'operatorName']"
                    :rules="{
                      required: true,
                      message: '请填写接收人真实姓名'
                    }"
                  >
                    <a-input
                      placeholder="请填写接收人真实姓名"
                      v-model:value="item.operatorName"
                      :maxLength="20"
                      style="width: 200px;"
                    />
                  </a-form-item>
                </a-col>
                <a-col>
                  <a-form-item
                    label="联系方式"
                    :name="['signatories', index, 'operatorContact']"
                    :rules="{
                      required: true,
                      trigger: 'change',
                      validator: checkContact
                    }"
                  >
                    <a-input
                      placeholder="请填写手机号/邮箱"
                      v-model:value="item.operatorContact"
                      style="width: 200px;"
                    />
                  </a-form-item>
                </a-col>
              </div>
              <a-col style="padding-top: 51px">
                <div class="flex-layout">
                  <a-form-item :name="['signatories', index, 'operator']" :rules="{ type: 'any' }">
                    <a-checkbox v-model:checked="item.operator">经办人签字</a-checkbox>
                  </a-form-item>
                  <a-form-item :name="['signatories', index, 'legalPerson']" :rules="{ type: 'any' }">
                    <a-checkbox v-model:checked="item.legalPerson">法定代表人签字</a-checkbox>
                  </a-form-item>
                  <a-form-item :name="['signatories', index, 'company']" :rules="{ type: 'any' }">
                    <a-checkbox v-model:checked="item.company">企业签章</a-checkbox>
                  </a-form-item>
                </div>
                <div class="warn-label" v-if="!item.operator && !item.legalPerson && !item.company">请选择签署人</div>
              </a-col>
            </a-row>
            <a-row :gutter="16" v-else-if="item.type === 1 && item.signatoryType === 1" class="padding24-style">
              <a-col>
                <a-form-item
                  label="个人姓名"
                  :name="['signatories', index, 'operatorName']"
                  :rules="{
                    required: true,
                    message: '请输入个人姓名'
                  }"
                >
                  <a-input
                    placeholder="请输入个人姓名"
                    v-model:value="item.operatorName"
                    :maxLength="20"
                    style="width: 240px;"
                  />
                </a-form-item>
              </a-col>
              <a-col>
                <a-form-item
                  label="联系方式"
                  :name="['signatories', index, 'operatorContact']"
                  :rules="{
                    required: true,
                    trigger: 'change',
                    validator: checkContact
                  }"
                >
                  <a-input placeholder="请填写手机号/邮箱" v-model:value="item.operatorContact" style="width: 200px;" />
                </a-form-item>
              </a-col>
            </a-row>
          </SignCard>
          <!-- 没有签署方 -->
          <NoSign v-if="!formState.signatories.length" />
        </div>
        <a-form-item
          label="签署截止日期"
          name="expireTime"
          :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 14 }"
          style="padding-top: 8px"
        >
          <a-date-picker
            v-model:value="formState.expireTime"
            format="YYYY-MM-DD"
            :disabled-date="disabledDate"
            valueFormat="YYYY-MM-DD"
          >
          </a-date-picker>
          <span style="margin-left:10px">
            <a-tooltip placement="topLeft">
              <template #title>
                <span style="text-align:center;display: inline-block">需在当天23:59:59前完成签署</span>
              </template>
              <QuestionCircleFilled />
            </a-tooltip>
          </span>
        </a-form-item>
        <a-form-item> </a-form-item>
        <a-form-item label="添加备注" :labelCol="{ span: 4 }" :wrapperCol="{ span: 14 }">
          <a-textarea
            :autoSize="{ minRows: 3 }"
            v-model:value="formState.applyText"
            placeholder="点击添加备注，最多100字"
            :maxlength="100"
            showCount
            @pressEnter="e => lineFeed(e)"
            style="width: 468px"
          />
        </a-form-item>
      </a-card>
    </a-form>
    <section class="submit-foot">
      <a-button @click.prevent="handleCancel" class="cancel-btn">取消</a-button>
      <a-button @click="onSubmit" :loading="submitLoading" class="submit-btn">提交</a-button>
    </section>
  </div>
</template>
<script setup>
import { computed, reactive, ref } from 'vue'
import moment from 'moment'
import { actionUrl } from '@/apis/upload.js'
import { getApplyProcessStaff } from '@/apis/businessManage'
import lineupload from '@/components/Upload/lineUpload'
import userAvatarTemplate from '@/views/seal/apply/components/userAvatarTemplate'
import SignCard from './components/signCard.vue'
import NoSign from './components/noSign.vue'
import { ConsoleSqlOutlined, DownOutlined, QuestionCircleFilled } from '@ant-design/icons-vue'
import { addElecApply, getApplySealList, getIdDetail, resubmitElecApply } from '@/apis/elecApply'
import { isCertification } from '@/apis/seal'
import { cmsNotice } from '@/utils/utils'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
const store = useStore()
const router = useRouter()
const route = useRoute()
// 登录账号的企业
const enterpriseName = ref()
const formRef = ref()
const documentId = computed(() => route.query.documentId) //申请Id
const submitLoading = ref(false)
const formState = reactive({
  ordinal: true,
  signatories: [
    {
      type: 0,
      name: enterpriseName,
      operatorContact: '15617659367',
      serialNo: 1,
      operator: false,
      legalPerson: false,
      company: true
    }
  ]
})
const actions = actionUrl
const uploadText = '支持pdf、docx、doc、xlsx、xls、jpg、jpeg、png格式文件 单个文件大小不超过30M'
// 字段规则配置
const rules = {
  fileName: [
    { required: true, message: '请输入流程主题' },
    {
      pattern: /^[\u9FA6-\u9FCB\u3400-\u4DB5\u4E00-\u9FA5_a-zA-Z0-9]{0,30}$/,
      message: '名称只能为中英文、数字、下划线组成'
    }
  ],
  sealId: [{ required: true, message: '请选择电子印章', type: 'number' }],
  flowId: [{ required: true, message: '请选择审批流程', type: 'number' }],
  applyFileIds: [{ required: true, message: '请上传签约文件' }],
  expireTime: [{ required: true, message: '请选择签署截止日期' }]
}

const approvalStaffList = ref([]) //流程人员
// 电子印章列表
const elecSelList = ref([])
// 联系方式正则校验
const checkContact = (rule, value) => {
  if (!value) {
    return Promise.reject('请填写手机号/邮箱')
  } else {
    const telReg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
    const mailReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    const boo = telReg.test(value) || mailReg.test(value)
    if (!boo) {
      return Promise.reject('联系方式格式不正确')
    }
  }
  return Promise.resolve()
}
// 审批流程数据
const applyProcessList = computed(() => {
  const arr_list = elecSelList.value.find(it => it.sealId === formState.sealId)?.sealFlowRelVOList
  console.log('当前印章的审批流程', arr_list)
  if (arr_list && arr_list.length === 1) {
    arr_list.forEach(it => (it.disabled = true))
  } else {
    arr_list.forEach(it => (it.disabled = false))
  }

  // formRef.value.validate(['flowId'])
  // 当前印章章已有流程 使用当前的流程，否则 选择一个
  return arr_list || store.state.globalData.applyProcessList
})
// 获取签章列表 接口数据
const getSealList = async () => {
  const { data } = await getApplySealList({ sealType: 2 })
  console.log('全部印章列表', data)
  elecSelList.value = data
}
// 是否可以添加发起方
const canAdd = computed(() => {
  const flag = formState.signatories.filter(it => !it.type)
  return !flag.length
})
// 审批流程列表接口数据
const getProcessList = async id => {
  const { data } = await getApplyProcessStaff({ id })
  console.log('当前审批流程人员列表', data)
  approvalStaffList.value = data
}
// 选择印章
const sealChange = id => {
  console.log('当前选中的印章的id', id)
  formRef.value.validate(['sealId'])
  // formRef.value.validate(['flowId'])
  let currentSealInfo = elecSelList.value.filter(item => item.sealId === id)
  console.log('当前选中印章的全部信息', currentSealInfo)
  if (currentSealInfo[0].sealFlowRelVOList.length) {
    formState.flowId = currentSealInfo[0].sealFlowRelVOList[0].flowId
    handleProcessChange(formState.flowId)
  } else {
    formState.flowId = undefined
    approvalStaffList.value = []
  }
}
// 审批流程 sel change
const handleProcessChange = id => {
  console.log('当前审批流程id', id)
  approvalStaffList.value = []
  getProcessList(id)
}
// 添加签署方
const addSigner = type => {
  let pushItem
  // 发起方
  if (type == 1) {
    pushItem = {
      type: 0,
      name: enterpriseName,
      serialNo: formState.signatories.length + 1,
      operator: false,
      legalPerson: false,
      company: true
    }
  } else if (type === 2) {
    // 企业
    pushItem = {
      type: 1,
      signatoryType: 0,
      name: undefined,
      serialNo: formState.signatories.length + 1,
      operator: false,
      legalPerson: false,
      company: true,
      operatorName: undefined,
      operatorContact: undefined
    }
  } else if (type == 3) {
    //  个人
    pushItem = {
      type: 1,
      signatoryType: 1,
      name: undefined,
      serialNo: formState.signatories.length + 1,
      // operatorName: undefined,
      operatorContact: undefined
    }
  }
  formState.signatories.push({ ...pushItem })
}
// 日期选择禁止日期
const disabledDate = current => {
  return current < moment().subtract(1, 'day')
}

/* 上传文件  */
const fileChange = (type, res) => {
  console.log(type, res)
  formState.applyFileIds = res.map(it => it.fileId || it.response.data[0].id)
  formRef.value.validate(['applyFileIds'])
}
//  提交表单
const onSubmit = () => {
  formRef.value.validate().then(async values => {
    if (!formState.signatories.length) {
      return cmsNotice('warning', '至少添加一位签署方信息才能提交！')
    }
    const params = { ...formState }
    params.applyText = params.applyText ? params.applyText.trim() : undefined
    const { flowId, sealId } = params
    params.sealFlowRelAddDTOList = [{ flowId, sealId }]
    for (let i = 0; i < params.signatories.length; i++) {
      // 个人
      if (params.signatories[i].type && params.signatories[i].signatoryType) continue
      if (!params.signatories[i].operator && !params.signatories[i].legalPerson && !params.signatories[i].company) {
        return
      }
      params.signatories[i].operator = +params.signatories[i].operator
      params.signatories[i].legalPerson = +params.signatories[i].legalPerson
      params.signatories[i].company = +params.signatories[i].company
      console.log(params.signatories)
    }
    if (documentId.value) {
      console.log(params.applyFileIds)
      params.applyFileIds = params.applyFileIds.map(it => it.fileId || it)
      params.expireTime = moment(params.expireTime).format('YYYY-MM-DD')
    }
    submitLoading.value = true
    const promiseThen = documentId.value ? resubmitElecApply(params) : addElecApply(params)
    promiseThen.then(({ success, code }) => {
      if (success) {
        cmsNotice('success', '提交成功')
        router.push('/seal/apply')
      } else if (code === 1067) {
        //印章或者流程已删除 点击提交的错误码，前端清空
        formState.sealId = undefined
        formState.flowId = undefined
        // formRef.value.validate(['sealId'])
        getSealList()
        submitLoading.value = false
      } else {
        submitLoading.value = false
      }
    })
  })
}
// 取消表单
const handleCancel = () => {
  router.go(-1)
}

// 回显改id数据
const getDetail = async id => {
  const { data } = await getIdDetail(id)
  console.log('回显数据', data)
  const {
    documentId,
    fileName,
    ordinal,
    sealId,
    sealFlowRelList,
    signatories,
    expireTime,
    applyText,
    documentApplyFileList
  } = data
  formState.fileName = fileName
  formState.ordinal = ordinal
  formState.sealId = sealId
  formState.sealId = sealFlowRelList?.length && sealFlowRelList[0].sealId
  formState.flowId = sealFlowRelList?.length && sealFlowRelList[0].flowId //审批流程
  approvalStaffList.value = sealFlowRelList?.length && sealFlowRelList[0].flowUsers
  formState.signatories = signatories.map((it, index) => ({
    ...it,
    serialNo: index + 1, //防止无序时重新提交没有这个字段  上移下移报错
    operator: it.signatoryType ? undefined : it.operatorNodeStatus,
    company: it.signatoryType ? undefined : it.companyNodeStatus,
    legalPerson: it.signatoryType ? undefined : it.legalPersonNodeStatus
  }))
  formState.expireTime = expireTime
  formState.applyText = applyText
  formState.id = documentId
  formState.applyFileIds = documentApplyFileList?.map(item => ({
    name: `${item.fileName}.${item.suffix}`,
    fileId: item.fileId
  }))
  console.log(formState)
}
route.query.documentId && getDetail(route.query.documentId)
//获取认证的企业名称  用于发起方显示
const getCertification = async () => {
  const { data } = await isCertification()
  enterpriseName.value = data.enterpriseName
}
/* 方法调用  */
getSealList()
getCertification()
</script>
<style lang="less" scoped>
#elec-wrapper {
  > form {
    padding: 0 24px;
  }
}

.sign-head {
  justify-content: space-between;
  padding-bottom: 24px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -24px;
    right: -24px;
    height: 1px;
    background: #e9e9e9;
  }
}

.head-title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  padding-right: 24px;
}

:deep(.sign-head .ant-btn) {
  // background: #c3161c;
  width: 118px;
  height: 32px;
  color: #fff;
  border: none;
}
:deep(.ant-calendar-picker) {
  width: 238px;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.fqf-item {
  padding: 24px 0 10px;
}
.warn-label {
  color: #ff4d4f;
}
:deep(.ant-form-item) {
  margin-bottom: 5px;
}
.enterprise-name {
  font-size: 14px;
  font-weight: 500;
  color: #131314;
}
.padding24-style {
  padding: 24px 0 10px;
}
:deep(.sign-form-item .ant-form-item-label .ant-form-item-required::before) {
  content: none;
}
</style>
